/* tslint:disable:no-console */
import { Splash } from './modules/core/Splash';

import './styles';

async function init() {
  const splash = new Splash();

  try {
    const boot = await import('./boot');
    await boot.start();
    splash.fade();
  } catch (err) {
    splash.error(err);
    console.error(err.stack || err);
    return;
  }
}

init().catch(err => {
  console.error('Failed to load app', err);
});
